.element_hero-image {
  /* aspect-ratio: 16 / 9; */
  width: 100%;
  max-height: 600px;
  display: flex;
  place-items: center;
  overflow: hidden;
  max-width: 1600px;
  margin: calc(4 * var(--space-base)) auto 0;
  position: relative;
}

.element_hero-image img {
  width: 100%;
  object-fit: cover;
}

.element_hero-image-credit {
  background: black;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 2px var(--space-base);
}
