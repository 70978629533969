.element_button {
  display: inline-flex;
  align-items: center;
  padding: 0 var(--space-base) 0;
  width:100%;
  min-height: 48px;
  border: 1px solid #000;
  justify-content: center;
  margin: 0 0 calc(var(--space-base) * 2);
}
