.element_icon-link {
  display: inline-flex;
  align-items: center;
  padding: 0 var(--space-base) 0 0;
  margin: 0 0 var(--space-base);
}

.element_icon-link:before {
  content: attr(data-content);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  flex: 0 0 35px;
  margin: 0 var(--space-base) 0 0;
  border-radius: 50%;
  background: var(--colour-base-black, #000);
  color: var(--colour-base-white, #fff);
  text-decoration: none;
}

.element_icon-link__blue:before {
  background: #5652f5;
}
.element_icon-link__green:before {
  background: #008845;
}

.element_icon-link__top {
  align-items: baseline;
}
