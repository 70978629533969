.element_content-card {
  background: var(--color-base-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: var(--animation-all);
  text-decoration: none;
  /* flex: 0 0 100%; */
  height: 100%;
}

.element_content-card__past {
  background: #f6f6f6;
}

.element_content-card__image {
  aspect-ratio: 4 / 3;
  background-size: cover;
  background-position: center;
  display: flex;
  padding: 0 16px;
  place-items: flex-end;
}

.element_content-card__video {
  aspect-ratio: 16 / 9;
}

.element_content-card__video iframe {
  width: 100%;
  height: 100%;
}

.element_content-card__past .element_content-card__image {
  aspect-ratio: 1 / 1;
}

.element_content-card:hover {
}

.element_content-card__category {
  display: inline-block;
  color: var(--color-base-white);
  background: var(--color-base-black);
  padding: 2px 8px; /* TODO: make responsive */
}

.element_content-card__category:empty {
  display: none;
}

.element_content-card__text {
  padding: 24px 16px; /* TODO: make responsive */
}

.typography_grey-900 {
  color: #545454;
}

.typography_paragraph_m1 {
  font-size: 1.6rem;
}

.element_content-card__blurb {
  margin: 0 0 var(--space-base);
}

.typography_tight {
  margin: 0;
}
