h1,
h2,
h3,
h4 {
  display: block;
  flex: 0 0 100%;
}

:global(.typography_heading-1),
h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
  margin: 0 0 var(--space-base);
}

:global(.typography_heading-2),
h2 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin: 0 0 calc(var(--space-base) / 2);
}

/* h2 {
  margin: 0 0 calc(1.5 * var(--space-base));
} */

:global(.typography_heading-3),
h3 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin: 0 0 calc(var(--space-base) / 2);
}

/* h3 {
  margin: 0 0 var(--space-base);
} */

:global(.typography_heading-4),
h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.6rem;
}

:global(.typography_paragraph-2) {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

:global(.typography_paragraph-8),
:global(.wp-block-heading) {
  font-size: 3rem;
  line-height: 4rem;
  font-size: 400;
}

@media screen and (min-width: 600px) {
  :global(.typography_heading-1),
  h1 {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 5.8rem;
    margin: 0 0 calc(2 * var(--space-base));
  }

  :global(.typography_heading-2),
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin: 0 0 var(--space-base);
  }

  /* h2 {
    margin: 0 0 calc(2 * var(--space-base));
  } */

  :global(.typography_heading-3),
  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin: 0 0 calc(var(--space-base) / 2);
  }

  /* h3 {
    margin: 0 0 calc(1.5 * var(--space-base));
  } */

  :global(.typography_paragraph-8),
  :global(.wp-block-heading) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  :global(.typography_heading-1),
  h1 {
    font-size: 7rem;
    line-height: 8.8rem;
    font-weight: 700;
    margin: 0 0 calc(3 * var(--space-base));
  }

  :global(.typography_heading-2),
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin: 0 0 calc(1.5 * var(--space-base));
  }

  /* h2 {
    margin: 0 0 calc(2.5 * var(--space-base));
  } */

  :global(.typography_heading-3),
  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin: 0 0 calc(var(--space-base) / 2);
  }

  /* h3 {
    margin: 0 0 calc(2 * var(--space-base));
  } */

  :global(.typography_paragraph-8),
  :global(.wp-block-heading) {
    font-size: 4.5rem;
    line-height: 5.8rem;
  }
}

.typography_extra-padding {
  margin: calc(2 * var(--space-base)) 0 0;
}

:global(p) {
  margin: 0 0 calc(var(--space-base) / 2);
}

:global(body) {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

:global(p + h2),
:global(p + h3) {
  margin-top: calc(2 * var(--space-base));
}

@media screen and (min-width: 1024px) {
  :global(p) {
    margin: 0 0 var(--space-base);
  }

  :global(p + h2),
  :global(p + h3) {
    margin-top: calc(3 * var(--space-base));
  }

  :global(body) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

:global(.typography_grey-900) {
  color: #545454;
}
