:root {
  /* Fonts */
  --typography-base: 62.5%;

  /* Spacing */
  --space-base: 8px;

  /* Grid */
  --grid-column-gap: var(--space-base);
  --grid-outer-margin: calc(2 * var(--space-base));

  /* Colours */
  --color-mono1: #f8f9fa;
  --color-mono2: #e9ecef;
  --color-mono3: #dee2e6;
  --color-mono4: #ced4da;
  --color-mono5: #adb5bd;
  --color-mono6: #6c757d;
  --color-mono7: #495057;
  --color-mono8: #343a40;
  --color-mono9: #212529;

  --grey-200: #f6f6f6;

  /* Brand */
  --color-brand-mid: #e10000;
  --color-brand-light: #e66969;
  --color-brand-dark: #7c0404;

  /* Stnadard transition */
  --animation-base: 0.3s ease-in-out;

  --color-base-black: #000;
  --color-base-white: #fff;

  /* Animation */
  --animation: 0.3s ease-in-out;
  --animation-all: all var(--animation);
}

@media screen {
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Red Hat Display', sans-serif;
  color: var(--color-base-black);
}

a {
  color: var(--color-base-black);
  text-decoration-line: underline;
  /* text-decoration-style: dashed; */
  text-underline-offset: 0.6rem;
  text-decoration-color: var(--color-base-black);
}

a:not(.anchor-no-highlight):hover {
  background: var(--color-base-black);
  color: var(--color-base-white);
}

a.inverted {
  color: var(--color-base-white);
  text-decoration-color: var(--color-base-white);
}

a.inverted:hover {
  background: var(--color-base-white);
  color: var(--color-base-black);
}

.element_grid-row {
  max-width: 1440px;
  margin: 0 0 calc(4 * var(--space-base));
}

.element_grid-row:last-child {
  margin: 0;
}

a.element_social-link:hover img {
  filter: invert(1);
}

.typography_paragraph-8,
.wp-block-heading {
  font-weight: 400;
}
