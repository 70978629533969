/* element responsive-grid */
/* :global { */
:global(.element_grid-container) {
  display: grid;
  place-items: center;
  padding: 0 var(--grid-outer-margin);
}

@media screen and (min-width: 600px) {
  :global(.element_grid-container) {
    padding: 0 calc(1.5 * var(--grid-outer-margin));
  }
}

@media screen and (min-width: 1024px) {
  :global(.element_grid-container) {
    padding: 0 calc(2 * var(--grid-outer-margin));
  }
}

:global(.element_grid-row) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-column-gap);
  width: 100%;
}

@media screen and (min-width: 600px) {
  :global(.element_grid-row) {
    grid-column-gap: calc(1.5 * var(--grid-column-gap));
    grid-row-gap: calc(1.5 * var(--grid-column-gap));
  }
}

@media screen and (min-width: 1024px) {
  :global(.element_grid-row) {
    grid-column-gap: calc(2 * var(--grid-column-gap));
    grid-row-gap: calc(2 * var(--grid-column-gap));
  }
}

:global([class*='element_grid-item']) {
  display: flex;
  flex-wrap: wrap;
  grid-column: auto / span 12;

  align-content: start;
}

:global([class*='element_grid-item'][data-grid-size*='s-half']) {
  grid-column: auto / span 6;
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-size*='m-half']) {
    grid-column: auto / span 6;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-size*='l-half']) {
    grid-column: auto / span 6;
  }
}

:global([class*='element_grid-item'][data-grid-size*='s-third']),
:global([class*='element_grid-item'][data-grid-size*='s-04']) {
  grid-column: auto / span 4;
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-size*='m-third']),
  :global([class*='element_grid-item'][data-grid-size*='m-04']) {
    grid-column: auto / span 4;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-size*='l-third']),
  :global([class*='element_grid-item'][data-grid-size*='l-04']) {
    grid-column: auto / span 4;
  }
}

:global([class*='element_grid-item'][data-grid-size*='s-quarter']) {
  grid-column: auto / span 3;
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-size*='m-quarter']) {
    grid-column: auto / span 3;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-size*='l-quarter']) {
    grid-column: auto / span 3;
  }
}

:global([class*='element_grid-item'][data-grid-size*='s-01']) {
  grid-column: auto / span 1;
}
:global([class*='element_grid-item'][data-grid-size*='s-02']) {
  grid-column: auto / span 2;
}
:global([class*='element_grid-item'][data-grid-size*='s-03']) {
  grid-column: auto / span 3;
}
:global([class*='element_grid-item'][data-grid-size*='s-08']) {
  grid-column: auto / span 8;
}
:global([class*='element_grid-item'][data-grid-size*='s-10']) {
  grid-column: auto / span 10;
}

@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-size*='m-01']) {
    grid-column: auto / span 1;
  }

  :global([class*='element_grid-item'][data-grid-size*='m-02']) {
    grid-column: auto / span 2;
  }

  :global([class*='element_grid-item'][data-grid-size*='m-03']) {
    grid-column: auto / span 3;
  }

  :global([class*='element_grid-item'][data-grid-size*='m-08']) {
    grid-column: auto / span 8;
  }

  :global([class*='element_grid-item'][data-grid-size*='m-10']) {
    grid-column: auto / span 10;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-size*='l-01']) {
    grid-column: auto / span 1;
  }

  :global([class*='element_grid-item'][data-grid-size*='l-02']) {
    grid-column: auto / span 2;
  }

  :global([class*='element_grid-item'][data-grid-size*='l-03']) {
    grid-column: auto / span 3;
  }

  :global([class*='element_grid-item'][data-grid-size*='l-08']) {
    grid-column: auto / span 8;
  }

  :global([class*='element_grid-item'][data-grid-size*='l-10']) {
    grid-column: auto / span 10;
  }
}

:global([class*='element_grid-item'][data-grid-size*='s-hide']) {
  display: none;
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-size*='s-hide']) {
    display: grid;
  }
}
@media screen and (max-width: 599px) {
  :global([class*='s-hide']) {
    display: none;
  }
}

:global([class*='element_grid-item'][data-grid-start*='s-1']) {
  grid-column-start: 1;
}
:global([class*='element_grid-item'][data-grid-start*='s-2']) {
  grid-column-start: 2;
}
:global([class*='element_grid-item'][data-grid-start*='s-3']) {
  grid-column-start: 3;
}
:global([class*='element_grid-item'][data-grid-start*='s-4']) {
  grid-column-start: 4;
}
:global([class*='element_grid-item'][data-grid-start*='s-5']) {
  grid-column-start: 5;
}
:global([class*='element_grid-item'][data-grid-start*='s-6']) {
  grid-column-start: 6;
}
:global([class*='element_grid-item'][data-grid-start*='s-7']) {
  grid-column-start: 7;
}
:global([class*='element_grid-item'][data-grid-start*='s-8']) {
  grid-column-start: 8;
}
:global([class*='element_grid-item'][data-grid-start*='s-9']) {
  grid-column-start: 9;
}
:global([class*='element_grid-item'][data-grid-start*='s-10']) {
  grid-column-start: 10;
}
:global([class*='element_grid-item'][data-grid-start*='s-11']) {
  grid-column-start: 11;
}
:global([class*='element_grid-item'][data-grid-start*='s-12']) {
  grid-column-start: 12;
}

@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-1']) {
    grid-column-start: 1;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-2']) {
    grid-column-start: 2;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-3']) {
    grid-column-start: 3;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-4']) {
    grid-column-start: 4;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-5']) {
    grid-column-start: 5;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-6']) {
    grid-column-start: 6;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-7']) {
    grid-column-start: 7;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-8']) {
    grid-column-start: 8;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-9']) {
    grid-column-start: 9;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-10']) {
    grid-column-start: 10;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-11']) {
    grid-column-start: 11;
  }
}
@media screen and (min-width: 600px) {
  :global([class*='element_grid-item'][data-grid-start*='m-12']) {
    grid-column-start: 12;
  }
}

@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-1']) {
    grid-column-start: 1;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-2']) {
    grid-column-start: 2;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-3']) {
    grid-column-start: 3;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-4']) {
    grid-column-start: 4;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-5']) {
    grid-column-start: 5;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-6']) {
    grid-column-start: 6;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-7']) {
    grid-column-start: 7;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-8']) {
    grid-column-start: 8;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-9']) {
    grid-column-start: 9;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-10']) {
    grid-column-start: 10;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-11']) {
    grid-column-start: 11;
  }
}
@media screen and (min-width: 1024px) {
  :global([class*='element_grid-item'][data-grid-start*='l-12']) {
    grid-column-start: 12;
  }
}

/* grid alignment */

:global([class*='element_grid-item'][data-grid-align*='s-right']) {
  justify-content: right;
}

/* } */
