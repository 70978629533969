.component_carousel {
  display: flex;
  width: 100%;

  max-width: 1600px;
  margin: 0 auto;

  overflow-x: scroll;
  overflow-y: hidden;

  scroll-snap-type: x mandatory;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.component_carousel::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.component_carousel__item-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: calc(2 * var(--space-base));
}

.component_carousel__item {
  width: 100vw;
  scroll-snap-align: start;
  aspect-ratio: 4 / 3;
  max-height: 800px;
}

.component_carousel__item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (min-width: 600px) {
  .component_carousel__item {
    width: calc(100vw / 12 * 10);
    /* width: 100%; */
    max-width: calc(1440px / 12 * 11);
    /* max-width: 800px; */
  }
}

@media screen and (min-width: 1024px) {
}
