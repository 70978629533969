html {
  font-size: var(--typography-base);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* header {
} */
main {
  flex: 1 1 auto;
}
/* footer {
} */
.component_header {
  padding: calc(2 * var(--space-base)) 0;
}

@media screen and (min-width: 600px) {
  .component_header {
    padding: calc(4 * var(--space-base)) 0;
  }
}

@media screen and (min-width: 1024px) {
  .component_header {
    padding: calc(5 * var(--space-base)) 0;
  }
}

.feature_white {
  padding: calc(10 * var(--space-base)) 0 calc(13 * var(--space-base));
}

.mini-feature_white {
  padding: calc(4 * var(--space-base)) 0;
}

.feature_grey {
  padding: calc(5 * var(--space-base)) 0;
  background: var(--grey-200);
}

.mini-feature_grey {
  padding: calc(4 * var(--space-base)) 0;
  background: var(--grey-200);
}

@media screen and (min-width: 600px) {
  .feature_grey {
    padding: calc(7 * var(--space-base)) 0;
    background: var(--grey-200);
  }

  .mini-feature_grey {
    padding: calc(5 * var(--space-base)) 0;
    background: var(--grey-200);
  }

  .mini-feature_white {
    padding: calc(5 * var(--space-base)) 0;
  }
}

@media screen and (min-width: 1024px) {
  .feature_grey {
    padding: calc(10 * var(--space-base)) 0;
    background: var(--grey-200);
  }

  .mini-feature_grey {
    padding: calc(8 * var(--space-base)) 0;
    background: var(--grey-200);
  }

  .mini-feature_white {
    padding: calc(8 * var(--space-base)) 0;
  }
}

.feature_black {
  /* padding: calc(3 * var(--space-base)) 0; */
  background: var(--color-base-black);
  color: var(--color-base-white);
}

.section_grey {
  background: var(--grey-200);
}

.foundation_spacing_section {
  margin: 0 0 calc(4 * var(--space-base));
}

@media screen and (min-width: 1024px) {
  .foundation_spacing_section {
    margin: 0 0 calc(5 * var(--space-base));
  }
}

@media screen and (min-width: 1024px) {
  .foundation_spacing_section {
    margin: 0 0 calc(6 * var(--space-base));
  }
}

.sr-only {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.iframe {
  width: 100%;
  aspect-ratio: 1 / 1;
}

@media screen and (min-width: 600px) {
  .iframe {
    aspect-ratio: 16 / 9;
  }
}

.iframe iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

.element_map-container {
  width: 100%;
  height: 100%;
  aspect-ratio: 4 / 3;
}

.spacing_top-medium {
  padding-top: calc(5 * var(--space-base));
}
.spacing_bottom-medium {
  padding-bottom: calc(5 * var(--space-base));
}

/* wp styles */
[data-hide=''] {
  display: none;
}

.wp-block-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: var(--space-base);
}

[style='flex-basis:66.66%'],
[style='flex-basis:33.33%'] {
  grid-column: span 4;
}

@media screen and (min-width: 1024px) {
  [style='flex-basis:66.66%'] {
    grid-column: span 3;
  }
  [style='flex-basis:33.33%'] {
    grid-column: span 1;
  }
}

p {
  max-width: 988px;
}
