nav li {
  list-style: none;
  padding: 0;
}

nav ul {
  padding: 0;
  display: flex;
}

.hideInput {
  position: fixed;
  left: -200vw;
  width: 0.1rem;
  height: 0.1rem;
  display: block;
  transform: scale(0.001);
  opacity: 0;
}

@media screen and (max-width: 599px) {
  nav {
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100vw;
    height: 100vh;
    max-height: 80px;

    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  nav > * {
    pointer-events: all;
  }

  input:checked + nav {
    max-height: 100vh;
    background: var(--grey-200, #f6f6f6);
  }

  nav ul {
    display: none;
    flex-direction: column;
    padding: calc(5 * var(--space-base)) calc(4 * var(--space-base));
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }

  input:checked + nav ul {
    display: flex;
  }

  nav li {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 700;
    padding: var(--space-base) 0;
    border-bottom: 1px solid var(--grey-400, #d3d3d3);
    width: 100%;
    text-align: left;
  }

  nav a {
    padding: 0 var(--space-base);
    text-decoration: none;
  }

  .element_nav-close {
    /* width: 100%; */
    background: #fff0;
    display: flex;
    height: calc(10 * var(--space-base));
    justify-content: right;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  input:checked + nav .element_nav-close {
    background: var(--color-base-white, #fff);
    width: 100%;
  }

  .element_nav-close:before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC41IDEzLjg3NVYxNi4xMjVIMzcuNVYxMy44NzVIMTAuNVpNMTAuNSAyMi44NzVWMjUuMTI1SDM3LjVWMjIuODc1SDEwLjVaTTEwLjUgMzQuMTI1VjMxLjg3NUgzNy41VjM0LjEyNUgxMC41WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==');
    background-size: cover;
    margin: calc(2 * var(--space-base));
  }
}

@media screen and (min-width: 600px) {
  nav li {
    padding: 0 0 0 18px;
  }

  .element_nav-close {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  nav li {
    padding: 0 0 0 24px;
  }
}
