.element_brand-logo-container {
  display: flex;
}

.element_brand-logo-container:hover {
  /* filter: invert(100%); */
  background: initial;
}

.element_brand-logo {
  max-width: calc(8 * var(--space-base));
  max-height: calc(6 * var(--space-base));
}

@media screen and (min-width: 600px) {
  .element_brand-logo {
    max-width: calc(10 * var(--space-base));
    max-height: calc(12 * var(--space-base));
  }
}

@media screen and (min-width: 1024px) {
  .element_brand-logo {
    max-width: calc(12 * var(--space-base));
  }
}

@media screen and (min-width: 1024px) {
  .element_image-wordmark {
    max-width: 100%;
  }
}

.element_wordmark-container {
  align-items: end;
  justify-content: end;
  text-align: right;
}
