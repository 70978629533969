.element_social-link-container {
  align-items: center;
  align-content: center;
}

.element_social-link {
  padding: calc(0.5 * var(--space-base));
  margin: 0 calc(1.5 * var(--space-base)) 0 0;
  width: calc(6 * var(--space-base));
  display: flex;
  align-items: center;
  justify-content: center;
}

.element_social-link img {
  width: 100%;
  filter: invert(100%);
}

@media screen and (min-width: 600px) {
  .element_social-link {
    width: calc(7 * var(--space-base));
  }
}

@media screen and (min-width: 1024px) {
  .element_social-link {
    margin: 0 calc(2 * var(--space-base)) 0 0;
    width: calc(7 * var(--space-base));
  }
}

.footer_link a {
  margin: var(--space-base) calc(2 * var(--space-base)) var(--space-base) 0;
  white-space: nowrap;
  display: inline-block;
}

@media screen and (min-width: 600px) {
  .footer_link a {
    margin: var(--space-base) calc(3 * var(--space-base)) var(--space-base) 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer_link a {
    margin: var(--space-base) calc(4 * var(--space-base)) var(--space-base) 0;
  }
}

.flex-wrap {
  flex-basis: 100%;
}

.partner-logo_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.partner-logo_row img {
  margin: 0 calc(2 * var(--space-base)) calc(2 * var(--space-base)) 0;
}

@media screen and (min-width: 600px) {
  .partner-logo_row img {
    margin: 0 calc(2 * var(--space-base)) calc(1.5 * var(--space-base)) 0;
  }
}

@media screen and (min-width: 1024px) {
  .partner-logo_row img {
    margin: 0 calc(4 * var(--space-base)) calc(1.5 * var(--space-base)) 0;
  }
}

footer .feature_black {
  padding: calc(3 * var(--space-base)) calc(1.5 * var(--space-base));
}
